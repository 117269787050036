import React, {useContext} from 'react';
import Providers from '~src/components/Providers';
import {ContentView, PageTemplate, Modals, Contacts} from '~src/components';
import {LocaleContext} from '~src/context';
import {IPage} from '~src/types';
import Seo from '~src/components/Seo';

interface ICalculation1Props extends IPage {}

const ContactsPage: React.FC<ICalculation1Props> = ({location, navigate, path}) => {
  const {
    pages: {contacts, index},
  } = useContext(LocaleContext);
  return (
    <Providers>
      <Seo
        title={contacts.meta.title}
        description={index.meta.description}
        keywords={index.meta.keywords}
        path={path}
      />
      <PageTemplate
        breadcrumbs={[...contacts.breadcrumbs, contacts.header1]}
        breadcrumbsSource={contacts.breadcrumbsSource}
      >
        <ContentView>
          <Contacts />
        </ContentView>
      </PageTemplate>
      <Modals navigate={navigate} path={path} location={location} />
    </Providers>
  );
};

export default ContactsPage;
